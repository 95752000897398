
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  name: 'HistorySmsFilterModal',
  components: { TmFormLine, TmButton, TmModal },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    checkboxLabel: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isNoReplies = ref(false)

    return {
      isNoReplies,
    }
  },
})
